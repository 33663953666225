<template>
    <div class="mb-24">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <div
            v-if="hasCredentials"
            id="bluemoon-guarantor-container"
        ></div>
        <div
            v-else
            class="w-full text-center align-center mt-4"
        >
            <p>please set up the blue moon property to continue</p>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapActions, mapGetters } from 'vuex';
import { BM_CREDENTIALS_ERROR, BM_PROPERTY_ERROR } from '@/utils/constants/leasing.js';
import { formatQuotedRentAmount } from '@/utils/Amount';

export default {
    name: 'GuarantorPrelease',
    components: { Loader },
    mixins: [NotifyMixin],
    data() {
        return {
            loading: false,
            hasCredentials: false,
            applicants: [],
            property: null,
            account_email: '',
            status: '',
        };
    },

    computed: {
        ...mapGetters({
            currentProfile: 'auth/currentProfile',
            getGuarantor: 'application/getGuarantor',
            getDocumentInstance: 'application/getDocumentInstance',
            getbluemoon_credentials: 'application/getbluemoon_credentials',
        }),
    },

    watch: {
        status: async function () {
            if (this.status !== '') {
                if (this.getGuarantor.application?.document_package_instance_id) {
                    await this.fetchDocumentIntance(this.getGuarantor.application?.document_package_instance_id);
                } else {
                    this.checkApplicationStatus(this.status);
                }
            }
        },
    },

    beforeMount() {
        this.clearState();
    },

    mounted() {
        this.fetchCurrentApplicationByOccupancyId(this.currentProfile.occupancyId, this.currentProfile.contactId);
    },

    methods: {
        ...mapActions({
            setGuarantor: 'application/setGuarantor',
            setApplication: 'application/setApplication',
            setDocumentInstance: 'application/setDocumentInstance',
            setbluemoon_credentials: 'application/setbluemoon_credentials',
            clearState: 'application/clearState',
        }),

        formatQuotedRentAmount,

        fetchbluemoon_credentials() {
            this.loading = true;
            this.$partnerDataProvider
                .getOne('community_blue_moon', {
                    customerUUID: this.currentProfile.customerId,
                    communityUUID: this.currentProfile.communityId,
                    purpose: 'AplicationLeasingGeneration', // Check this with Nick later.
                })
                .then(res => {
                    if (res.platformData) {
                        this.hasCredentials = true;
                        this.setbluemoon_credentials(res.platformData);
                        this.getAuthBlueMoonToken();
                    }
                })
                .catch(() => {
                    this.loading = false;
                    this.hasCredentials = false;
                    this.notifyError(BM_CREDENTIALS_ERROR);
                });
        },

        fetchCurrentApplicationByOccupancyId(occupancy_id, contact_id) {
            this.loading = true;
            this.$leasingServiceDataProvider
                .getOne('household', {
                    occupancy_id,
                })
                .then(async res => {
                    this.applicants = res[0]?.persons
                        .filter(person => person.person_role === 'Applicant')
                        .map(applicant => {
                            return `${applicant.first_name} ${applicant.last_name}`;
                        });
                    let household = {
                        ...res[0],
                        guarantor: res[0]?.persons
                            .filter(person => person.person_role === 'Guarantor' && person.contact_id === contact_id)
                            .map(guarantor => {
                                return {
                                    ...guarantor,
                                    applicationProcessStatus: guarantor.applicationProcessStatus[0]?.application_process_status,
                                };
                            }),
                    };
                    delete household.persons;
                    household = {
                        ...household,
                        ...household.guarantor[0],
                        application: household.guarantor[0]?.application[0]?.application,
                        expected_lease_duration: `${household.expected_lease_duration}`,
                        quoted_rent_amount: this.formatQuotedRentAmount(household.quoted_rent_amount),
                        pet: [],
                        vehicle: [],
                    };
                    delete household.householdCombinedStatus;
                    delete household.householdHasNote;
                    delete household.backgroundScreening;
                    delete household.identityVerification;
                    delete household.guarantor;
                    delete household.relation;
                    this.status = household.applicationProcessStatus;
                    this.setGuarantor(household);
                })
                .catch(error => {
                    this.notifyError(error.message);
                })
                .finally(() => (this.loading = false));
        },

        async fetchDocumentIntance(document_id) {
            await this.$leasingServiceDataProvider
                .getOne('document_instance', {
                    document_package_instance_id: document_id,
                    business_unit_id: this.currentProfile.communityId,
                    customer_id: this.currentProfile.customerId,
                })
                .then(res => {
                    this.setDocumentInstance({
                        ...res.payload,
                        document_package_instance_id: res.document_package_instance_id,
                    });
                    this.checkApplicationStatus(this.status);
                })
                .catch(error => {
                    this.notifyError(error.message);
                });
        },

        checkApplicationStatus(status) {
            if (status.toLowerCase() === 'application submitted - not paid') {
                this.$router.push({ name: 'application_service.application_payment_guarantor' });
            } else if (
                status.toLowerCase() === 'background screening' ||
                status.toLowerCase() === 'mitigation' ||
                status.toLowerCase() === 'accepted' ||
                status.toLowerCase() === 'declined'
            ) {
                this.$router.push({ name: 'application_service.confirmation' });
            } else if (status.toLowerCase() === 'identity verification') {
                this.$router.push({ name: 'application_service.background_screening_guarantor' });
            } else this.$nextTick(this.fetchbluemoon_credentials);
        },

        getAuthBlueMoonToken() {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials.username,
                        password: this.getbluemoon_credentials.password,
                        scope: 'full',
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials.client_id,
                        client_secret: this.getbluemoon_credentials.client_secret,
                    },
                })
                .then(async res => {
                    this.token = res.access_token;
                    await this.getAccount(this.getbluemoon_credentials?.serial_number, res.access_token);
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        getAccount(accountId, token) {
            this.loading = true;
            this.$bmServiceDataProvider
                .getList('account', {
                    id: accountId,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.account_email = res.data.email;
                    if (this.account_email) await this.getProperty(accountId, token);
                })
                .catch(() => {
                    this.notifyError(BM_PROPERTY_ERROR);
                    this.loading = false;
                });
        },

        getProperty(accountId, token) {
            this.loading = true;
            this.$bmServiceDataProvider
                .getList('property', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    this.property = res.data.find(property => property.account_id === accountId);
                    if (this.property) await this.getAuthBlueMoonTokenForGuarantor(this.property.id);
                })
                .catch(() => {
                    this.notifyError('An error occurred getting the property from Blue Moon');
                    this.loading = false;
                });
        },

        getAuthBlueMoonTokenForGuarantor(property_id) {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: this.getbluemoon_credentials.username, // Fake property (15430) serial number
                        password: this.getbluemoon_credentials.password,
                        scope: 'guarantor',
                        grant_type: 'password',
                        client_id: this.getbluemoon_credentials.client_id,
                        client_secret: this.getbluemoon_credentials.client_secret,
                    },
                })
                .then(async res => {
                    this.token = res.access_token;
                    await this.leaseApplication(res.access_token, property_id);
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        leaseApplication(token, property_id) {
            this.loading = true;
            const params = {
                view: 'form',

                enableNavigation: false,

                showNavigationButtons: true,

                includeFieldData: true,

                preleaseData: {
                    owner_name: this.property.name,
                    owner_email: this.account_email,
                    owner_phone: this.property.telephone,
                    residents: this.applicants,
                },

                fieldData: {
                    guarantor_name: `${this.getGuarantor.first_name} ${this.getGuarantor.last_name}`,
                    email: this.getGuarantor.email,
                    phone: this.getGuarantor.cell_phone,
                },
            };
            // eslint-disable-next-line no-undef
            const bluemoonElements = new BluemoonElements(token, property_id);

            const guarantorElement = bluemoonElements.load('bluemoon-guarantor-container', 'bm-guarantor', params);

            guarantorElement.addEventListener('onElementLoaded', () => {
                this.loading = false;
            });

            guarantorElement.addEventListener('onGuarantorSaved', e => {
                this.setDocumentInstance({
                    ...e.detail.field_data,
                    id: e.detail.id,
                    key: e.detail.key,
                });
            });

            guarantorElement.addEventListener('onNavigationRequest', e => {
                if (e.detail.toLowerCase() != 'next') return;

                const errors = this.checkRequiredFields();
                if (errors.length) {
                    errors.forEach(error => this.notifyError(error));
                } else {
                    this.$router.push({ name: 'application_service.guarantor_contract_review' });
                }
            });
        },

        checkRequiredFields() {
            const errors = [];

            // Validate applicant's birth date
            if (this.getDocumentInstance.birth_date === null) {
                errors.push('birth date must not be empty');
            }

            // Validate applicant's social security number
            if (this.getDocumentInstance.social_security_number === null) {
                errors.push('social security number must not be empty');
            }

            // Validate applicant's email format
            if (this.getDocumentInstance.email === null) {
                errors.push('email must not be empty');
            } else {
                const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                if (!emailPattern.test(this.getDocumentInstance.email)) {
                    errors.push('email format is not valid');
                }
            }

            // Validate address field
            if (this.getDocumentInstance.current_address === null) {
                errors.push('address must not be empty');
            }

            // Validate current city field
            if (this.getDocumentInstance.current_city === null) {
                errors.push('city must not be empty');
            }

            // Validate state field
            if (this.getDocumentInstance.current_st === null) {
                errors.push('state must not be empty');
            }

            // Validate zip code field
            if (this.getDocumentInstance.current_zip === null) {
                errors.push('zip code must not be empty');
            }

            return errors;
        },
    },
};
</script>
